// @flow
import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import styles from './FooterTablet.module.scss';
import IconDownWhite from '../../images/icon-down-light-grey.svg';
import { footerMenu } from './const';

const ArrowDownIcon = () => <img src={IconDownWhite} alt={'icon-down'} />;

const FooterTablet = () => {
  return (
    <div className={styles.footerWrapper}>
      <footer>
        <Link to="/">
          <img
            className={styles.logo}
            src={require('../../images/logo-white.svg')}
            alt="Paidy"
            height="40"
            width="142"
            loading="lazy"
          />
        </Link>
        <nav className={styles.app}>
          <OutboundLink
            title="Paidy | App Store"
            className={styles.badge}
            href="https://apps.apple.com/jp/app/paidy/id1220373112"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('../../images/badge-app-store.svg')}
              alt="Paidy | App Store"
              width="140"
              height="42"
              loading="lazy"
            />
          </OutboundLink>
          <OutboundLink
            title="Paidy | Google Play"
            className={styles.badge}
            href="https://play.google.com/store/apps/details?id=com.paidy.paidy&hl=ja"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('../../images/badge-google-play.svg')}
              alt="Paidy | Google Play"
              width="140"
              height="42"
              loading="lazy"
            />
          </OutboundLink>
        </nav>
        {footerMenu.map((menuItem) => (
          <Accordion className={styles.mainNavDropdown} key={menuItem.title}>
            <AccordionSummary
              expandIcon={<ArrowDownIcon />}
              aria-controls={`${menuItem.title}-content`}
              id={`${menuItem.title}-header`}
            >
              <div className={styles.title}>{menuItem.title}</div>
            </AccordionSummary>
            <AccordionDetails>
              <nav>
                {menuItem.subMenu &&
                  menuItem.subMenu.map((subMenuItem) => (
                    <>
                      {subMenuItem.isExternalLink ? (
                        <OutboundLink
                          key={subMenuItem.title}
                          className={styles.externalLink}
                          href={subMenuItem.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {subMenuItem.title}
                        </OutboundLink>
                      ) : (
                        <Link
                          to={subMenuItem.link}
                          key={subMenuItem.title}
                          state={subMenuItem.state}
                        >
                          {subMenuItem.title}
                        </Link>
                      )}
                    </>
                  ))}
              </nav>
            </AccordionDetails>
          </Accordion>
        ))}
        <div className={styles.misc}>
          <nav className={styles.sns}>
            <OutboundLink
              className={styles.badge}
              href="https://twitter.com/paidyjp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../images/icon-x-white.svg')}
                alt="Paidy | Twitter"
                width="40"
                height="40"
                loading="lazy"
              />
            </OutboundLink>
            <OutboundLink
              className={styles.badge}
              href="https://www.linkedin.com/company/paidy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../images/icon-linkedin-outline.svg')}
                alt="Paidy | Linkedin"
                width="40"
                height="40"
                loading="lazy"
              />
            </OutboundLink>
          </nav>
          <small>&copy; Paidy Inc.</small>
        </div>
      </footer>
    </div>
  );
};

FooterTablet.defaultProps = { isMerchant: false };

export default FooterTablet;
