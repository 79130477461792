// @flow
//Core
import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { Grid } from '@material-ui/core';
//Local
import typography from '../../../styles/typography.module.scss';
import { navigationStyles } from './styles';

const MediaCenterNavigation = () => {
  const styles = navigationStyles();
  const navigation = [
    {
      title: 'メディアセンター',
      url: '/media_center/',
      isPartiallyDisabled: true,
    },
    {
      title: 'Paidyファン',
      url: '/media_center/fun/',
    },
    {
      title: 'Paidyプレス',
      url: '/media_center/press/',
    },
    {
      title: 'Paidyワーク&ライフ',
      url: '/media_center/work_life/',
    },
  ];

  const isPartiallyActive = (
    { isPartiallyCurrent, isCurrent },
    isPartiallyDisabled
  ) =>
    (isPartiallyCurrent && !isPartiallyDisabled) || isCurrent
      ? {
          className: classNames(
            styles.navItem,
            typography.textLgDark,
            'active'
          ),
        }
      : null;

  const itemsJSX = navigation.map((item: Object, index) => (
    <Grid item className={styles.navItemWrap} xs={12} sm={6} md={3} key={index}>
      <Link
        to={item.url}
        activeClassName="active"
        className={classNames(styles.navItem, typography.textLgDark)}
        key={index}
        getProps={(props) => isPartiallyActive(props, item.isPartiallyDisabled)}
      >
        {item.title}
      </Link>
    </Grid>
  ));

  return (
    <div className={styles.container}>
      <nav className={styles.navigation}>
        <div className={styles.navigationContent}>
          <Grid container>{itemsJSX}</Grid>
        </div>
      </nav>
    </div>
  );
};

export default MediaCenterNavigation;
