// @flow
import React, { useState } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import useScrollPosition from '../../hooks/useScrollPosition';
import LinkBox from './LinkBox';
import { mainMenuMobile as mainMenu } from './const';
import styles from './HeaderTablet.module.scss';

const paidyLogo = require('../../images/logo-color.svg');

const HeaderTablet = ({
  siteTitle,
  pathname,
  hasNoHeaderNav,
}: {
  siteTitle: string,
  pathname: string,
  hasNoHeaderNav?: boolean,
}) => {
  const [isTopPostion, setIsTopPostion] = useState(true);
  const [isHeaderNavActive, setIsHeaderNavActive] = useState(false);

  useScrollPosition(
    ({ currPos }) => {
      setIsTopPostion(currPos.y === 0);
    },
    [isTopPostion]
  );

  const onClick = () => {
    setIsHeaderNavActive(false);
  };

  return (
    // Add an extra level of div to force React to update entire component for tablet
    <header
      className={cx(styles.header, styles.bgWhite, {
        [styles.isActive]: isHeaderNavActive,
        [styles.scrolling]: !isTopPostion,
      })}
    >
      <div className={styles.topBlock}>
        {!hasNoHeaderNav && (
          <button
            className={cx(styles.menu, 'a11y')}
            onClick={() => setIsHeaderNavActive(!isHeaderNavActive)}
          >
            Menu
          </button>
        )}
        <Link to="/" className={styles.logo} onClick={onClick}>
          <img alt={siteTitle} src={paidyLogo} height="22" loading="lazy" />
        </Link>
        <OutboundLink
          href="https://my.paidy.com/login/"
          target="_blank"
          rel="noopener noreferrer"
          loading="lazy"
          className={styles.rightExternalLink}
        >
          ログイン
        </OutboundLink>
      </div>
      {isHeaderNavActive && (
        <nav className={styles.mainNav}>
          {mainMenu.map((menuItem) =>
            menuItem.subMenu ? (
              <LinkBox
                to={menuItem.link}
                text={menuItem.title}
                key={menuItem.title}
              >
                <nav
                  className={cx(
                    pathname.endsWith(menuItem.link) ? styles.active : ''
                  )}
                >
                  {menuItem.subMenu.map((subMenuItem) => (
                    <Link
                      to={subMenuItem.link}
                      onClick={onClick}
                      className={cx({
                        [styles.active]:
                          pathname.endsWith(subMenuItem.link) ||
                          (!subMenuItem.rootLink &&
                            pathname.includes(subMenuItem.link)),
                      })}
                      key={subMenuItem.title}
                      state={subMenuItem.state}
                    >
                      {subMenuItem.title}
                    </Link>
                  ))}
                </nav>
              </LinkBox>
            ) : (
              <Link
                className={cx(
                  pathname.endsWith(menuItem.link) ? styles.active : ''
                )}
                to={menuItem.link}
                onClick={onClick}
                key={menuItem.title}
              >
                {menuItem.title}
              </Link>
            )
          )}
          <Link
            href="https://paidy.com/merchant/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.linkExternal}
          >
            事業者の方へ
          </Link>
          <Link
            href="https://paidy.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.linkExternal}
          >
            一般のお客様へ
          </Link>
        </nav>
      )}
    </header>
  );
};

HeaderTablet.defaultProps = {
  siteTitle: ``,
  isTransparent: false,
  hasNoHeaderNav: false,
};

export default HeaderTablet;
