/* eslint-disable import/prefer-default-export */
import { LANGUAGE } from 'src/constants';

export const footerMenu = [
  {
    link: '/about',
    rootLink: true,
    title: '会社概要',
    witActiveSubMenu: true,
    subMenu: [
      {
        link: '/about#team',
        title: 'マネジメントチーム',
      },
      {
        link: '/about#overview',
        title: 'プロフィール',
      },
      {
        link: '/dream/',
        title: 'Paidyが目指す未来',
      },
    ],
  },
  {
    link: '/news',
    rootLink: true,
    title: 'ニュースルーム',
    witActiveSubMenu: true,
    subMenu: [
      {
        link: '/press_kit',
        title: 'プレスキット',
      },
    ],
  },
  {
    link: '/service',
    rootLink: true,
    title: '事業内容',
    witActiveSubMenu: true,
    subMenu: [
      {
        link: 'https://paidy.com/merchant/',
        title: '事業者の方へ',
        isExternalLink: true,
      },
      {
        link: 'https://paidy.com/',
        title: '一般のお客様へ',
        isExternalLink: true,
      },
    ],
  },
  {
    link: '/recruit',
    rootLink: true,
    title: '採用情報',
    witActiveSubMenu: true,
    subMenu: [
      {
        link: '/recruit#openings',
        title: '募集中のポジション',
      },
      {
        link: '/hr-blog',
        title: 'HRからのお知らせ',
      },
      {
        link: '/recruit',
        state: { language: LANGUAGE.EN },
        title: 'Careers',
      },
    ],
  },
];
