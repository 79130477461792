/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';
import { headerHeight } from '../../../styles/_vars.scss';

export const navigationStyles = makeStyles(() => ({
  container: {
    paddingTop: headerHeight,
  },
  navigation: {
    backgroundColor: '#F6F3F2',
  },
  navigationContent: {
    maxWidth: '1200px',
    margin: '0 auto',
  },
  navItemWrap: {
    textAlign: 'center',
    paddingTop: '24px',
    paddingBottom: '24px',
    '&:not(:last-child)': {
      borderRight: '1px solid #D8D8D8',
    },
  },
  navItem: {
    '&.active': {
      fontWeight: 'bold',
    },
  },
}));
