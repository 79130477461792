/* eslint-disable import/prefer-default-export */
import { LANGUAGE } from 'src/constants';

export const mainMenu = [
  {
    link: '/about/',
    rootLink: true,
    title: '会社概要',
    witActiveSubMenu: true,
    subMenu: [
      {
        link: '/about#team',
        title: 'マネジメントチーム',
      },
      {
        link: '/about#overview',
        title: 'プロフィール',
      },
    ],
  },
  {
    link: '/news/',
    rootLink: true,
    title: 'ニュースルーム',
    witActiveSubMenu: true,
  },
  {
    link: '/service/',
    rootLink: true,
    title: '事業内容',
    witActiveSubMenu: true,
  },
  {
    link: '/recruit/',
    rootLink: true,
    title: '採用情報',
    witActiveSubMenu: true,
    subMenu: [
      {
        link: '/recruit#openings',
        title: '募集中のポジション',
      },
      {
        link: '/hr-blog',
        title: 'HRからのお知らせ',
      },
      {
        link: '/recruit/',
        state: { language: LANGUAGE.EN },
        title: 'Careers',
      },
    ],
  },
];

export const mainMenuMobile = [
  {
    link: '/about/',
    rootLink: true,
    title: '会社概要',
    witActiveSubMenu: true,
    subMenu: [
      {
        link: '/about',
        title: '会社概要',
      },
      {
        link: '/about#team',
        title: 'マネジメントチーム',
      },
      {
        link: '/about#overview',
        title: 'プロフィール',
      },
    ],
  },
  {
    link: '/news/',
    rootLink: true,
    title: 'ニュースルーム',
    witActiveSubMenu: true,
  },
  {
    link: '/service/',
    rootLink: true,
    title: '事業内容',
    witActiveSubMenu: true,
  },
  {
    link: '/recruit/',
    rootLink: true,
    title: '採用情報',
    witActiveSubMenu: true,
    subMenu: [
      {
        link: '/recruit',
        title: '採用情報',
      },
      {
        link: '/recruit#openings',
        title: '募集中のポジション',
      },
      {
        link: '/hr-blog',
        title: 'HRからのお知らせ',
      },
      {
        link: '/recruit/',
        state: { language: LANGUAGE.EN },
        title: 'Careers',
      },
    ],
  },
];
