// @flow
import React, { useState } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import useScrollPosition from '../../hooks/useScrollPosition';
import { mainMenu } from './const';
import styles from './Header.module.scss';

const Header = ({
  siteTitle,
  headerAlpha,
  hasNoHeaderNav,
  pathname,
}: {
  siteTitle: string,
  headerAlpha: number,
  hasNoHeaderNav?: boolean,
  pathname: string,
}) => {
  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent ? { className: styles.activeLink } : {};
  };

  const [isTopPostion, setIsTopPostion] = useState(true);

  useScrollPosition(
    ({ currPos }) => {
      setIsTopPostion(currPos.y === 0);
    },
    [isTopPostion]
  );

  return (
    <div
      className={styles.headerWrapper}
      style={{
        background: `rgba(255, 255, 255, ${headerAlpha})`,
      }}
    >
      <header className={cx(styles.header, styles.bgWhite)}>
        <div className={styles.headerInner}>
          <div className={styles.internals}>
            <h1 title={siteTitle} className={styles.logo}>
              <Link to="/">
                <img
                  className={styles.logoWhite}
                  alt={siteTitle}
                  src={require('../../images/logo-white.svg')}
                  height="22"
                  width="80"
                  loading="lazy"
                />
                <img
                  className={styles.logoColor}
                  alt={siteTitle}
                  src={require('../../images/logo-color.svg')}
                  height="22"
                  width="80"
                  loading="lazy"
                />
              </Link>
            </h1>
            {!hasNoHeaderNav && (
              <nav className={styles.mainNav}>
                {mainMenu.map((menuItem) => (
                  <div
                    className={cx(styles.linkBox, {
                      [styles.withDropdown]: !!menuItem.subMenu,
                      [styles.withActiveDropdown]:
                        !!menuItem.subMenu &&
                        menuItem.witActiveSubMenu &&
                        pathname.startsWith(menuItem.link),
                    })}
                    key={menuItem.title}
                  >
                    <Link
                      to={menuItem.link}
                      activeClassName={styles.active}
                      getProps={isPartiallyActive}
                    >
                      <div className={styles.link}>{menuItem.title}</div>
                    </Link>
                    {menuItem.subMenu && (
                      <nav className={styles.subNav}>
                        {menuItem.subMenu.map((subMenuItem) => (
                          <Link
                            to={subMenuItem.link}
                            key={subMenuItem.title}
                            className={cx({
                              [styles.active]:
                                pathname.endsWith(subMenuItem.link) ||
                                (!subMenuItem.rootLink &&
                                  pathname.includes(subMenuItem.link)),
                            })}
                            state={subMenuItem.state}
                          >
                            {subMenuItem.title}
                          </Link>
                        ))}
                      </nav>
                    )}
                  </div>
                ))}
              </nav>
            )}
          </div>
          {!hasNoHeaderNav && (
            <nav className={styles.externals}>
              <Link
                className={cx('btnV2 hover-primary', styles.headerExternalBtn)}
                href="https://paidy.com/merchant/"
                target="_blank"
                rel="noopener noreferrer"
              >
                事業者の方へ
              </Link>
              <OutboundLink
                className={cx('btnV2 hover-primary', styles.headerExternalBtn)}
                href="https://paidy.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                一般のお客様へ
              </OutboundLink>
            </nav>
          )}
        </div>
      </header>
    </div>
  );
};

Header.defaultProps = {
  siteTitle: ``,
  isTransparent: false,
  hasNoHeaderNav: false,
};

export default Header;
