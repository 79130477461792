/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { titlePathnameMap } from '../constants';
import jsonLd from '../json-ld';

function SEO({
  description,
  lang,
  meta,
  title,
  keywords,
  ogImage,
  twitterImage,
  pathname,
  audience,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            keywords
            description
            ogImage
            twitterImage
            twitterUsername
            robots
          }
        }
      }
    `
  );

  const titlePrefix = site.siteMetadata.title || 'Paidy';
  const titleFrags = [titlePrefix];

  if (titlePathnameMap[pathname]) {
    titleFrags.push(titlePathnameMap[pathname]);
  }

  const seo = {
    title: title || titleFrags.join(' | '),
    description: description || site.siteMetadata.description,
    keywords: keywords || site.siteMetadata.keywords,
    ogImage:
      ogImage || `${site.siteMetadata.siteUrl}${site.siteMetadata.ogImage}`,
    twitterImage:
      twitterImage ||
      `${site.siteMetadata.siteUrl}${site.siteMetadata.twitterImage}`,
    twitterUsername: site.siteMetadata.twitterUsername,
  };

  const metaRobots = site.siteMetadata.robots
    ? {
        name: `robots`,
        content: site.siteMetadata.robots,
      }
    : {};

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      meta={[
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          name: `description`,
          content: seo.description,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: seo.ogImage,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          property: 'twitter:image',
          content: seo.twitterImage,
        },
        {
          name: `twitter:creator`,
          content: seo.twitterUsername,
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
        metaRobots,
      ].concat(meta)}
      link={[
        { rel: 'preconnect', href: 'https://images.ctfassets.net' },
        { rel: 'preconnect', href: 'https://www.google-analytics.com' },
        { rel: 'preconnect', href: 'https://s.yimg.jp' },
        { rel: 'preconnect', href: 'https://bid.g.doubleclick.net' },
      ]}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            ...jsonLd,
            description: seo.description,
            keywords: seo.keywords,
            headline: seo.title,
            url: `https://paidy.com${pathname}`,
            audience,
          }),
        },
        {
          async: 'async',
          defer: 'defer',
          innerHTML: `
          (function(d){
            var l = d.createElement("link");
            var s = d.getElementsByTagName("script")[0];
            l.rel = "stylesheet";
            l.href = "https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700,900&display=swap&subset=japanese";
            s.parentNode.insertBefore(l, s);
          })(document);
          `,
        },
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``,
  title: 'Paidy',
  pathname: '/',
  audience: 'consumer',
};

export default SEO;
