// @flow
import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import styles from './Footer.module.scss';
import { footerMenu } from './const';
import { gtag } from '../../utils';

const Footer = () => {
  return (
    <div className={styles.footerWrapper}>
      <footer>
        <div className={styles.footerNav}>
          {footerMenu.map((menuItem) => (
            <nav key={menuItem.title}>
              <div className={styles.title}>
                <Link to={menuItem.link}>{menuItem.title}</Link>
              </div>
              {menuItem.subMenu &&
                menuItem.subMenu.map((subMenuItem) =>
                  subMenuItem.isExternalLink ? (
                    <OutboundLink
                      key={subMenuItem.title}
                      className={styles.externalLink}
                      href={subMenuItem.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {subMenuItem.title}
                    </OutboundLink>
                  ) : (
                    <Link
                      to={subMenuItem.link}
                      key={subMenuItem.title}
                      state={subMenuItem.state}
                    >
                      {subMenuItem.title}
                    </Link>
                  )
                )}
            </nav>
          ))}
        </div>
        <div className={styles.footerConnect}>
          <Link className={styles.footerLogo} to="/">
            <img
              className={styles.logo}
              src={require('../../images/logo-white.svg')}
              alt="Paidy"
              loading="lazy"
              height="40"
              width="142"
            />
          </Link>
          <div className={styles.misc}>
            <nav className={styles.sns}>
              <OutboundLink
                className={styles.badge}
                href="https://twitter.com/paidyjp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../images/icon-x-white.svg')}
                  alt="Paidy | Twitter"
                  width="40"
                  height="40"
                  loading="lazy"
                />
              </OutboundLink>
              <OutboundLink
                className={styles.badge}
                href="https://www.linkedin.com/company/paidy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../images/icon-linkedin-outline.svg')}
                  alt="Paidy | Linkedin"
                  width="40"
                  height="40"
                  loading="lazy"
                />
              </OutboundLink>
            </nav>
            <nav className={styles.app}>
              <a
                title="Paidy | App Store"
                className={styles.badge}
                href="https://apps.apple.com/jp/app/paidy/id1220373112"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  gtag({
                    event: 'app_download',
                    ga_click_area: 'footer',
                    ga_platform: 'App Store',
                  });
                }}
              >
                <img
                  src={require('../../images/badge-app-store.svg')}
                  alt="Paidy | App Store"
                  width="140"
                  height="42"
                  loading="lazy"
                />
              </a>
              <a
                title="Paidy | Google Play"
                className={styles.badge}
                href="https://play.google.com/store/apps/details?id=com.paidy.paidy&hl=ja"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  gtag({
                    event: 'app_download',
                    ga_click_area: 'footer',
                    ga_platform: 'Google Play',
                  });
                }}
              >
                <img
                  src={require('../../images/badge-google-play.svg')}
                  alt="Paidy | Google Play"
                  width="140"
                  height="42"
                  loading="lazy"
                />
              </a>
            </nav>
            <small>&copy; Paidy Inc.</small>
          </div>
        </div>
      </footer>
    </div>
  );
};

Footer.defaultProps = { isMerchant: false };

export default Footer;
