/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import get from 'lodash.get';
import { Location } from '@reach/router';
import Hidden from '@material-ui/core/Hidden';
import cx from 'classnames';

import SEO from '../SEO';
import Header from '../Header/Header';
import HeaderTablet from '../Header/HeaderTablet';
import Footer from '../Footer/Footer';
import FooterTablet from '../Footer/FooterTablet';
import MediaCenterNavigation from '../mediaCenterSections/navigation';

// import { isIPad } from '../../utils';
import { titlePathnameMap } from '../../constants';

import './Layout.scss';

const BASE_ALPHA = 1;

const Layout = ({
  isMerchant,
  isPaymentsPage,
  isMediaCenter,
  noBG,
  hasNoHeader,
  hasNoHeaderNav,
  hasNoFooter,
  children,
  SEOProps,
  isHrPage,
  isRecruitPage,
}) => {
  const headerAlpha = BASE_ALPHA;

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div id="paidy_root" className={cx({ 'no-bg': noBG }, 'root-mobile')}>
      <Location>
        {({ location }) => (
          <>
            <SEO
              keywords={undefined}
              ogImage={undefined}
              twitterImage={undefined}
              title={get(titlePathnameMap, location.pathname, '')}
              pathname={location.pathname}
              audience={isMerchant ? 'merchant' : 'consumer'}
              {...SEOProps}
            />
            {!hasNoHeader && (
              <>
                <Hidden lgUp>
                  <HeaderTablet
                    siteTitle={data.site.siteMetadata.title}
                    pathname={location.pathname}
                    hasNoHeaderNav={hasNoHeaderNav}
                  />
                </Hidden>
                <Hidden mdDown>
                  <Header
                    siteTitle={data.site.siteMetadata.title}
                    headerAlpha={headerAlpha}
                    pathname={location.pathname}
                  />
                </Hidden>
              </>
            )}
            {isMediaCenter && <MediaCenterNavigation />}
            <main
              className={cx(
                { payments: isPaymentsPage },
                { merchant: isMerchant },
                { hr: isHrPage },
                { recruit: isRecruitPage }
              )}
            >
              {children}
            </main>
            {!hasNoFooter && (
              <>
                <Hidden lgUp>
                  <FooterTablet />
                </Hidden>
                <Hidden mdDown>
                  <Footer />
                </Hidden>
              </>
            )}
          </>
        )}
      </Location>
    </div>
  );
};

Layout.defaultProps = {
  isMerchant: false,
  isPaymentsPage: false,
  isCompanyPage: false,
  isMediaCenter: false,
  noBG: false,
  hasNoHeader: false,
  hasNoHeaderNav: false,
  hasNoFooter: false,
  SEOProps: {},
  isHrPage: false,
};

export default Layout;
